<template>
    <div class="services" >
        <div class="row large_screen">
            <div class="col-lg-12 col-sm-12 pb-4 guide" data-aos="fade-down">
                <h1>{{ this.$i18n.locale == 'ar' ? 'خدماتنا.. حيث تلتقي الجودة بالثقة' : 'Our services.. where quality meets trust'
                    }}
                </h1>
                <p>
                    {{$t('ServicesWeOffer')}}
                </p>
            </div>
            <div class="col-md-12 services-list">
                <div class="row">
                    <div class=" mb-4 m-auto serve" v-for="(item, index)  in Services" :key="item.id">
                        <a :href="serviceLinks[index]" target="_blank" rel="noopener noreferrer">
                            <div class="item">
                                <div class="image">
                                    <img :src="item.image" loading="lazy" alt="service" style="width:85px; height:85px;">
                                </div>
                                <h2>{{ item.title }}</h2>
                                <p>{{ item.description }}</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row small_screen">
          <div class="col-lg-3 guide" data-aos="fade-down">
            <h1>{{ this.$i18n.locale == 'ar' ? 'خدماتنا.. حيث تلتقي الجودة بالثقة' : 'Our services.. where quality meets trust'
              }}
            </h1>
            <p>
              {{$t('ServicesWeOffer')}}
            </p>
          </div>

          <div class="col-lg-9 col-sm-12 services-list">
            <swiper class="swiper" :space-between="30" :speed="1000" :breakpoints="swiperOptions.breakpoints"
                    :slidesPerView="3" navigation :pagination="{ type: 'progressbar', }">
              <swiper-slide v-for="(item, index)  in Services" :key="item.id" @mouseenter="handleMouseEnter"
                            @mouseleave="handleMouseLeave">
                <a :href="serviceLinks[index]" target="_blank" rel="noopener noreferrer">
                  <div class="item">
                    <div class="image">
                      <img :src="item.image" loading="lazy" alt="service" style="width:85px; height:85px;">
                    </div>
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.description }}</p>
                  </div>
                </a>
              </swiper-slide>

            </swiper>
          </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            swiperOptions: {
                breakpoints: {
                    300: { slidesPerView: 1, spaceBetween: 50 },
                    576: { slidesPerView: 2, spaceBetween: 25 },
                    767: { slidesPerView: 2, spaceBetween: 25 },
                    991: { slidesPerView: 3, spaceBetween: 25 },
                    1200: { slidesPerView: 3, spaceBetween: 25 },
                }
            },
          serviceLinks: [
            '/services/company-formation',
            '/services/electronic-services',
            '/services/foreign-services',
            '/services/trademark-registration',
            '/services/legal-services',
          ],
        }
    },
    computed: {
        Services() {
            return this.$store.state.Services_Type
        }
    },
    methods: {
        handleMouseEnter() {
            this.isHovered = true;
        },
        handleMouseLeave() {
            this.isHovered = false;
        }
    },
    mounted() {
        this.$store.dispatch('GetServicesType');
    }
};
</script>

<style scoped>

</style>
